<template>
    <CustomTable
        id_table="choix_tiers"
        primaryKey="tiers_id"
		:transformer="['TierTransformer', 'light']"
        :preselected_items="preselected_tiers"
        :selected_ids.sync="selected_ids"
        :hrefsRoutes="config_table_hrefs"
		:selectMode="unique ? 'single' : 'multi'"
    />
</template>

<script type="text/javascript">
    import CustomTable from 'GroomyRoot/components/Table/CustomTable'
    import Horse from '@/mixins/Horse.js'
    import Navigation from '@/mixins/Navigation.js'
    import Shutter from '@/mixins/Shutter.js'

	export default {
		name: "horseSelectionV2",
        mixins:  [Horse, Shutter, Navigation],
        props: {
            selected_tiers_ids: { type: Array, default: () => [] },
			unique: { type: Boolean, default: false }
        },
		data () {
			return {
				preselected_tiers: [],
                config_table_hrefs: {
                    'tiers_rs': {
                        routeUniqueName: 'tiersFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers_id'
                        }
                    }
                },
				events_tab: {
					'TableAction::goToSelectTiers': this.onSelectTiers
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
            init_component() {
                this.preselected_tiers = [...this.selected_tiers_ids]
            },
			onSelectTiers(tiers_ids) {
				this.ok(tiers_ids)
			}
		},
		computed: {
			selected_ids: {
				get() {
					return this.selected_tiers_ids
				},
				set(val) {
					this.$emit('update:selected_tiers_ids', val)

					if(this.unique && val.length > 0) {
						this.onSelectTiers(val)
					}
				}
			}
		},
        components: {
			CustomTable,
			ButtonValidation: () => import('GroomyRoot/components/Buttons/Validation'),
		}
	}
</script>
